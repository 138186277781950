import "gatsby-omni-font-loader"

const theme = {
  space: [
    0,
    `0.25rem`,
    `0.5rem`,
    `1rem`,
    `2rem`,
    `4rem`,
    `8rem`,
    `16rem`,
  ],
  fonts: {
    body: 'proximanova, sans-serif',
    heading: 'silkamono, monospace',
    monospace: 'silkamono, monospace',
  },
  fontSizes: [
    `0.8rem`,
    `1rem`,
    `1.25rem`,
    `1.563rem`,
    `1.953rem`,
    `2.441rem`,
    `3.052rem`,
    `4.768rem`,
    `5.96rem`,
    `9.313rem`,
    `11.642rem`,
  ],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  letterSpacings: [
    0,
    `0.5px`,
    `1px`,
  ],
  lineHeights: {
    body: 1.5,
    heading: 1.25,
    none: 1,
    snug: 1.375,
    relaxed: 1.625,
  },
  colors: {

    // propuesta demente v2
    text: `#464646`,
    background: `#fffff`,
    primary: `#2f858e`,
    secondary: `#ffceba`,
    tertiary: `#d64a37`,

    // segunda propuesta diseñadoras
    // text: `#5c5c5c`,
    // background: `#fffff`,
    // primary: `#72459b`,
    // secondary: `#faa834`,
    // tertiary: `#00ac5f`,

    // primera propuesta diseñadoras
    // text: `#595959`,
    // background: `#fffff`,
    // primary: `#0065b1`,
    // secondary: `#fbee3b`,
    // tertiary: `#ef3c34`,

    muted: `#c5c5c5`,
    white: `#ffffff`,
    black: `#000000`,

    // propuesta demente v2
    gray: [null, `#ececec`, `#b5b5b5`, `#6a6a6a`, `#464646`, `#313131`, `#232323`],

    // grayscale segunda propuesta diseñadoras
    // gray: [null, `#e5e5e5`, `#bebebe`, `#979797`, `#5c5c5c`, `#3f3f3f`, `#212121`],

    // grayscale primera propuesta diseñadoras https://www.colorhexa.com/595959
    // gray: [null, `#595959`, `#f6f6f6`, `#e2e2e2`, `#cfcfcf`, `#3c3c3c`, `#1e1e1e`],
  },
  /*
    Al usar este array hay que declarar 6 valores
    0 = min-width 0px default
    1 = min-width 640px
    2 = min-width 769px
    3 = min-width 1024px
    4 = min-width 1280px
    5 = min-width 1680px
  */
  breakpoints: [
    `640px`,
    `768px`,
    `1024px`,
    `1280px`,
    `1640px`,
  ],
  sizes: {
    px: `1px`,
    "0": `0`,
    "1": `0.25rem`,
    "2": `0.5rem`,
    "3": `0.75rem`,
    "4": `1rem`,
    "5": `1.25rem`,
    "6": `1.5rem`,
    "8": `2rem`,
    "10": `2.5rem`,
    "12": `3rem`,
    "16": `4rem`,
    "20": `5rem`,
    "24": `6rem`,
    "32": `8rem`,
    "40": `10rem`,
    "48": `12rem`,
    "56": `14rem`,
    full: `100%`,
    screenHeight: `100vh`,
    screenWidth: `100vw`,
    phoneMaxWidht: `85vw`,
    tabletMaxWidth: `90vw`,
    desktopMaxWidth: `85vw`,
    desktopLargeMaxWidth: `80vw`,
  },
  forms: {
    input: {
      borderRadius: 0,
    },
    textarea: {
      borderRadius: 0,
    }
  },
  buttons: {
    primary: {
      color: `white`,
      border: `1px solid white`,
      borderRadius: 0,
      backgroundColor: `transparent`,
      cursor: `pointer`,
      fontSize: 0,
      fontWeight: `bold`,
      p: 3,
    },
    secondary: {
      color: `white`,
      border: `none`,
      borderRadius: 0,
      backgroundColor: `black`,
      cursor: `pointer`,
      fontSize: 0,
      fontWeight: `bold`,
      p: 3,
    },
  },
  images: {
    logo: {
      width: `1rem`,
      height: `1rem`,
      borderRadius: `100%`,
    },
  },
  layout: {
    container: {
      maxWidth: [
        `phoneMaxWidht`,
        `phoneMaxWidht`,
        `tabletMaxWidth`,
        `tabletMaxWidth`,
        `desktopMaxWidth`,
        `desktopLargeMaxWidth`
      ],
    },
  },
  text: {
    heading: {
      color: `text`,
      fontFamily: `heading`,
      fontWeight: `heading`,
      lineHeight: `heading`,
    },
    base: {
      color: `text`,
      fontFamily: `body`,
      fontWeight: `body`,
      lineHeight: 1.33,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
    },
  },
  links: {
    nav: {
      fontSize: `0`,
      fontWeight: `bold`,
      pr: 3,
    }
  },
  styles: {
    root: {
      boxSizing: `border-box`,
      fontSize: 1,
      padding: 0,
      variant: `text.base`,
    },
    h1: {
      variant: `text.heading`,
      fontSize: [5, 5, 5, 5, 6, 8],
      mt: 2,
      mb: 1,
    },
    h2: {
      variant: `text.heading`,
      fontSize: [2, 2, 2, 2, 3, 4],
      mt: [4, 4, 4, 4, 5, 5],
      mb: 1,
    },
    h3: {
      variant: `text.heading`,
      fontSize: [1, 1, 1, 1, 2, 3],
      mt: [4, 4, 4, 4, 5, 5],
      mb: 1,
    },
    h4: {
      variant: `text.heading`,
      fontSize: 1,
      mt: 4,
      mb: 1,
    },
    h5: {
      variant: `text.heading`,
      fontSize: 1,
      mt: 4,
      mb: 1,
    },
    h6: {
      variant: `text.heading`,
      fontSize: 1,
      mt: 4,
      mb: 1,
    },
    p: {
      variant: `text.base`,
      fontSize: [1, 1, 3, 3, 3, 3],
      mb: [3, 3, 3, 3, 4, 4],
    },
    a: {
      borderBottom: `1px dotted`,
      color: `primary`,
      textDecoration: `none`,
      transition: `all 0.3s ease-in-out`,
      ":hover": {
        borderBottom: `1px solid`,
      },
    },
    pre: {
      fontFamily: `monospace`,
      overflowX: `auto`,
      code: {
        color: `inherit`
      }
    },
    code: {
      fontFamily: `monospace`,
      fontSize: `inherit`
    },
    table: {
      width: `100%`,
      borderCollapse: `separate`,
      borderSpacing: 0
    },
    th: {
      textAlign: `left`,
      borderBottomStyle: `solid`
    },
    td: {
      textAlign: `left`,
      borderBottomStyle: `solid`
    },
    img: {
      maxWidth: `100%`
    },
    hr: {
      bg: `muted`,
      border: 0,
      height: `1px`,
      m: 3,
    },
    ul: {
      listStyle: `square`,
      marginLeft: 3,
      paddingLeft: 3,
    },
    li: {
      fontFamily: `body`,
      fontSize: [1, 1, 3, 3, 3, 3],
      my: [0, 1],
    },
  }
}

export default theme